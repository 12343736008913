.content {
    border-radius: 30px;
    padding: 0px 22px;
    @apply min-w-0 min-h-screen bg-slate-100 flex-1 pb-10 dark:bg-darkmode-700;
    @include media-breakpoint-down(md) {
        @apply px-4 max-w-full;
    }
    &:before {
        content: "";
        @apply w-full h-px block;
    }
}
